import React from "react";
import VitalIcon from "../assets/vital.svg";
import "./ResponsivenessForAll.scss";
const FirstSection = () => {
  return (
    <div className="d-flex flex-column align-items-center">
      <div className="container">
        <div
          className="d-flex flex-column align-items-center boxStyle"
          style={{ gap: "24px" }}
        >
          <img src={VitalIcon} alt="icon" width={"64px"} />
          <div
            className="d-flex flex-column"
            style={{ gap: "12px", textAlign: "center" }}
          >
            <span
              style={{
                color: "#3A3737",
                fontSize: "18px",
                fontWeight: 600,
                textAlign: "center",
              }}
            >
              Empowering Sudanese Organization: Overcoming Funding Challenges
            </span>
            <span
              style={{
                textAlign: "center",
                fontWeight: 600,
                fontSize: "32px",
              }}
            >
              <span style={{ color: "#4158CF" }}>KAPorg </span>empowers Sudanese
              Organizations by
              <span style={{ color: "#4158CF" }}>
                {" "}
                facilitating donor connections
              </span>{" "}
              and{" "}
              <span style={{ color: "#4158CF" }}>
                expanding funding networks.
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FirstSection;
