import React from "react";
import "./styles.scss";
import manImg from "../assets/man-smiling.svg";

const ExpectedOutcomes = () => {
  return (
    <div className="principles-component-sudan">
      <div className="container">
        <div className="row g-0">
          <h2 className="principles-title">Expected Outcomes:</h2>
          <div className="col-md-6 d-flex justify-content-center align-items-center">
            <img src={manImg} alt="Child Eating" className="img-fluid" />
          </div>
          <div className="col-md-6 d-flex align-items-center">
            <div className="text-content">
              <span
                style={{ color: "#003DAA", fontSize: "32px", fontWeight: 600 }}
              >
                Expected Outcomes:
              </span>
              <ul
                className="d-flex flex-column"
                style={{ marginTop: "32px", gap: "24px" }}
              >
                <li>
                  <span style={{ fontWeight: 700 }}>Enhanced Knowledge: </span>
                  Organizations improve their understanding of capacity-building
                  frameworks and organizational standards.
                </li>
                <li>
                  <span style={{ fontWeight: 700 }}>
                    Identified Challenges:
                  </span>{" "}
                  Local Organizations can clearly identify gaps and challenges
                  in their organizational capacity.
                </li>
                <li>
                  <span style={{ fontWeight: 700 }}>
                    Increased Reliability:
                  </span>{" "}
                  Organizations learn how to become more reliable partners,
                  actively engaging in the design and implementation of
                  humanitarian efforts.
                </li>
                <li>
                  <span style={{ fontWeight: 700 }}>
                    Improved Organizational Capacity:
                  </span>
                  Organizations are equipped with knowledge and tools for
                  effective governance, planning, resource mobilization, and
                  sustainability.
                </li>
                <li>
                  <span style={{ fontWeight: 700 }}>
                    Sustainable Capacity Building:
                  </span>{" "}
                  Organizations can continually identify and address
                  organizational capacity gaps, applying targeted strategies to
                  build their capacity over time.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpectedOutcomes;
