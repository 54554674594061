import Header from "./components/Header.jsx";
import FirstSection from "./components/FirstSection.jsx";
import React from "react";
import Footer from "./components/Footer.jsx";
import OurApproach from "./components/OurApproach.jsx";
import UnlockingInnovation from "./components/UnlockingInnovation.jsx";
import WhatWeDo from "./components/WhatWeDo.jsx";
import Policies from "./components/Policies.jsx";

function LoginKAS() {
  return (
    <div className="App">
      <Header />
      <FirstSection />
      <OurApproach />
      <WhatWeDo />
      <UnlockingInnovation />
      <Policies />
      <Footer />
    </div>
  );
}

export default LoginKAS;
