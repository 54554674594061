import React from "react";
import { Button } from "antd";
import AssessmentIcon from "../assets/assessment-icon.svg";
import EducationIcon from "../assets/educational-resource.svg";
import EvidenceIcon from "../assets/evidence-based.svg";
import DevActions from "../assets/dev-actions.svg";
import CostEffective from "../assets/cost-effective.svg";

const Advantages = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const spanStyle = {
    fontSize: "16px",
    color: "#3A3737",
  };

  return (
    <div
      className="d-md-flex optimizingContainer-sudan"
      style={{ gap: "74px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <div
              className="d-flex flex-column"
              style={{ gap: "24px", width: "100%" }}
              // use @media queries to make width 100% in mobile
            >
              <span
                style={{
                  fontSize: "32px",
                  color: "#003DAA",
                  fontWeight: 600,
                }}
              >
                The platform has several advantages for local Sudanese NGOs:
              </span>
              <span style={spanStyle}>
                Achieve Donor Readiness, Compliance, and Expanded Partnerships.
              </span>
              <Button
                onClick={scrollToTop}
                className="btn-sudan"
                type="primary"
                style={{
                  width: "fit-content",
                  backgroundColor: "#4158CF",
                  fontWeight: 700,
                  borderRadius: "4px",
                }}
              >
                Get started now
              </Button>
            </div>
          </div>
          <div className="col-md-8">
            <div className="gridContainer">
              <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                <img src={AssessmentIcon} alt="organizational assessments" />
                <div className="d-flex flex-column" style={{ gap: "5px" }}>
                  <span
                    style={{
                      color: "#4158CF",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    Self-Assessment Tools:
                  </span>
                  <span style={{ color: "#3A3737" }}>
                    Sudan KAPorg provides user-friendly tools for
                    self-assessment across various aspects of Organizational
                    infrastructure, including governance, administration, human
                    resources management, financial management, program
                    management, and project performance management. This helps
                    organizations strengthen their strategic planning.
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                <img src={EducationIcon} alt="" />
                <div className="d-flex flex-column" style={{ gap: "5px" }}>
                  <span
                    style={{
                      color: "#4158CF",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    Educational Resource:
                  </span>
                  <span style={{ color: "#3A3737" }}>
                    The platform serves as an educational resource for local
                    Organizations and their staff, offering insights into the
                    main components of an effective Organization. It also
                    provides a framework for tracking progress through periodic
                    self-assessments.
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                <img src={EvidenceIcon} alt="" />
                <div className="d-flex flex-column" style={{ gap: "5px" }}>
                  <span
                    style={{
                      color: "#4158CF",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    Evidence-Based Design:
                  </span>
                  <span style={{ color: "#3A3737" }}>
                    Sudan KAPorg is designed based on international and regional
                    best practices in humanitarian and development work,
                    ensuring that its approaches are grounded in effectiveness
                    and sustainability.
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                <img src={DevActions} alt="" />
                <div className="d-flex flex-column" style={{ gap: "5px" }}>
                  <span
                    style={{
                      color: "#4158CF",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    Prioritized Capacity Development Actions:
                  </span>
                  <span style={{ color: "#3A3737" }}>
                    Through its scoring system, the platform highlights areas
                    for capacity development, helping Organizations prioritize
                    actions that will strengthen their technical capabilities in
                    the humanitarian sector.
                  </span>
                </div>
              </div>
              <div className="d-flex align-items-start" style={{ gap: "10px" }}>
                <img src={CostEffective} alt="" />
                <div className="d-flex flex-column" style={{ gap: "5px" }}>
                  <span
                    style={{
                      color: "#4158CF",
                      fontSize: "18px",
                      fontWeight: 600,
                    }}
                  >
                    Cost-Effective and Accessible:
                  </span>
                  <span style={{ color: "#3A3737" }}>
                    The platform is free to use, and participants only need
                    internet access and an account to start. Multiple
                    participants from the same Organization can use the platform
                    simultaneously, enhancing the validity of assessments by
                    reducing individual biases.
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Advantages;
