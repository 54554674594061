/* eslint-disable */
import React from "react";
import style from "./Footer.module.scss";
import SudanFlag from "../assets/Flag-Sudan.svg";
import KaporgWhiteIcon from "../assets/KAPorg-white.svg";
import WorldIcon from "../assets/world.svg";
import MailIcon from "../assets/mail.svg";
import LinkedInIcon from "../assets/brand-linkedin.svg";

const Footer = () => {
   // Function to handle WhatsApp redirect
   const redirectToWhatsApp = (phoneNumber) => {
      // Removing any special characters from phone number
      const cleanNumber = phoneNumber.replace(/[^\d]/g, "");
      return `https://wa.me/${cleanNumber}`;
   };
   return (
      <>
         <div className={style.footerAll} style={{ backgroundColor: "#4158cf" }}>
            <div className="container">
               <div className={style.footerSection}>
                  <div className={style.firstRow}>
                     <div>
                        <div
                           className="headerLogo-sudan"
                           style={{ padding: 0, background: "#4158CF" }}
                        >
                           <div className="container" style={{ padding: 0 }}>
                              <div className="logo d-flex align-items-center">
                                 <img src={SudanFlag} alt="forAfrika" />
                                 <span
                                    style={{
                                       fontSize: "18px",
                                       color: "#fff",
                                       fontWeight: 600
                                    }}
                                 >
                                    Sudan
                                 </span>
                                 <div className="vertical-line"></div>

                                 <img src={KaporgWhiteIcon} alt="forAfrika" />
                              </div>
                           </div>
                        </div>
                        <p className={style.p}>
                           KAPorg is a product of
                           <br />
                           <strong>
                              International Advisory, Products and Systems Ltd. (i-APS)
                           </strong>
                        </p>
                     </div>
                  </div>
                  <div className={style.secondPart}>
                     <div className={style.contactSuportBrandsSection}>
                        <h5 className={style.h5}>Contact us</h5>
                        <div className={style.contactContent}>
                           <h7 className={style.h7Contact}>
                              5805 Governors View Lane,
                              <br /> Alexandria, Virginia, 22310.
                              <br /> United States of America
                           </h7>

                           <div className={style.contentt}>
                              <img src={MailIcon} alt="mail" />
                              <a
                                 className={style.h7}
                                 style={{ textDecoration: "none" }}
                                 href={"mailto:info@kaptechgroup.com"}
                              >
                                 info@kaptechgroup.com
                              </a>
                           </div>
                           <div className={style.worldBrand}>
                              <a href="https://panel.kaporg.com/" rel="noopener noreferrer">
                                 <img src={WorldIcon} alt="world" />
                              </a>
                              <a
                                 href="https://www.linkedin.com/company/kaporg/"
                                 target="_blank"
                                 rel="noopener noreferrer"
                              >
                                 <img src={LinkedInIcon} alt="linkedin" />
                              </a>
                           </div>
                        </div>
                     </div>
                     <div className={style.contactSuportBrandsSection}>
                        <h5 className={style.h5}>Support</h5>
                        <div className={style.supportBrandContent}>
                           <h7 className={style.h7}>
                              <a
                                 style={{ color: "white", textDecoration: "none" }}
                                 href={"mailto:info@kaptechgroup.com"}
                              >
                                 Help center
                              </a>
                           </h7>

                           <h7 className={style.h7}>
                              <a
                                 style={{ color: "white", textDecoration: "none" }}
                                 href="/contact-us"
                              >
                                 Sales team
                              </a>
                           </h7>
                           <h7 className={style.h7}>
                              <a style={{ color: "white", textDecoration: "none" }} href="/pricing">
                                 Pricing plans
                              </a>
                           </h7>
                        </div>
                     </div>
                     <div className={style.contactSuportBrandsSection}>
                        <h5 className={style.h5}>Brands</h5>
                        <div className={style.supportBrandContent}>
                           <h7 className={style.h7}>
                              <a
                                 style={{ color: "white", textDecoration: "none" }}
                                 href="https://kapsurvey.com/"
                              >
                                 KAPsurvey
                              </a>
                           </h7>
                           <h7 className={style.h7}>
                              <a
                                 style={{ color: "white", textDecoration: "none" }}
                                 href="https://kapcourse.com/"
                              >
                                 KAPcourse
                              </a>
                           </h7>
                           <h7 className={style.h7}>
                              <a
                                 style={{ color: "white", textDecoration: "none" }}
                                 href="https://www.kapinsights.com/"
                              >
                                 KAPinsights
                              </a>
                           </h7>
                           <h7 className={style.h7}>
                              <a
                                 style={{ color: "white", textDecoration: "none" }}
                                 href="https://kaporg.com/"
                              >
                                 KAPorg
                              </a>
                           </h7>
                        </div>
                     </div>
                  </div>
               </div>
               <div
                  style={{
                     width: "100%",
                     height: "0.2px",
                     backgroundColor: "#fff",
                     marginTop: "20px"
                  }}
               ></div>
               <div className="d-flex" style={{ gap: "30px" }}>
                  <span
                     style={{
                        marginTop: "24px",
                        fontSize: "12px",
                        color: "#fff",
                        fontWeight: 500
                     }}
                  >
                     <a
                        href="https://www.kaporg.com/terms-of-service"
                        target="_blank"
                        style={{ color: "#fff" }}
                     >
                        Terms of Service
                     </a>
                  </span>
                  <span
                     style={{
                        marginTop: "24px",
                        fontSize: "12px",
                        color: "#fff",
                        fontWeight: 500
                     }}
                  >
                     <a
                        href="https://www.kaporg.com/privacy-policy"
                        target="_blank"
                        style={{ color: "#fff" }}
                     >
                        Privacy Policy
                     </a>
                  </span>
                  <span
                     style={{
                        marginTop: "24px",
                        fontSize: "12px",
                        color: "#fff",
                        fontWeight: 500
                     }}
                  >
                     <a
                        href="https://www.kaporg.com/cookie-policy"
                        target="_blank"
                        style={{ color: "#fff" }}
                     >
                        Cookie Policy
                     </a>
                  </span>
               </div>
            </div>
         </div>
      </>
   );
};
export default Footer;
