import React from "react";
import "./styles.scss";
import SudanezeMan from "../assets/sudaneze-man.svg";
import BlueTick from "../assets/blueTick.svg"

const SocialChangeSudan = () => {
  return (
    <div
      className="mainContainer-sudan"
      style={{
        backgroundColor: "#DFE6FF",
      }}
    >
      <div className="container">
        <div className="mainContainer-wrapper">
          <div>
            <img
              src={SudanezeMan}
              alt="People"
              className="img-fluid"
              style={{
                height: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                borderRadius: "16px",
              }}
            />
          </div>
          <div className="text-container-sudan" style={{ gap: "32px" }}>
            <div className="d-flex flex-column" style={{ gap: "24px" }}>
              <span
                style={{
                  fontSize: "32px",
                  fontWeight: 600,
                  color: "#003DAA",
                  margin: 0,
                }}
              >
                KAPorg: Empowering Social Change in Sudan
              </span>
              <span
                style={{
                  fontSize: "16px",
                  color: "#3A3737",
                }}
              >
                KAPorg is a cutting-edge platform that accelerates localization
                in humanitarian aid and development across Sudan. By bringing
                together key stakeholders and strengthening organizations,
                KAPorg helps Organizations tackle challenges and achieve their
                goals.
              </span>
              <div>
                <ul
                  className="d-flex flex-column sudan-elevate-list"
                  style={{ padding: "0 0 0 25px", gap: "24px" }}
                >
                  <li
                    style={{
                      color: "#3A3737",
                      fontWeight: 600,
                      letterSpacing: "0.36px",
                    }}
                  >
                    <img src={BlueTick} alt="blue-tick"/>
                    <span>Optimize Localization: Enhance the effectiveness of projects
                    tailored to Sudan's needs.</span>
                  </li>
                  <li
                    style={{
                      color: "#3A3737",
                      fontWeight: 600,
                      letterSpacing: "0.36px",
                    }}
                  >
                    <img src={BlueTick} alt="blue-tick"/>
                    <span>Broaden Your Network: Connect with donors and partners to
                    boost support and impact.</span>
                  </li>
                  <li
                    style={{
                      color: "#3A3737",
                      fontWeight: 600,
                      letterSpacing: "0.36px",
                    }}
                  >
                    <img src={BlueTick} alt="blue-tick"/>
                    <span>Build Capacity: Utilize resources to strengthen and sustain
                    your NGO's operations.</span>
                  </li>
                </ul>
                <span>
                  KAPorg is committed to fostering impactful change and
                  supporting Sudanese NGOs in their vital work.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SocialChangeSudan;
