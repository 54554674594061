import React, { useEffect, useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, Spin } from "antd";
import "./styles.scss";
import SudanFlag from "../assets/Flag-Sudan.svg";
import KaporgBlueIcon from "../assets/KAPorg-blue.svg";
import Banner from "../assets/Banner.svg";
import { FormattedMessage } from "react-intl";
import { eye } from "react-icons-kit/feather/eye";
import { eyeOff } from "react-icons-kit/feather/eyeOff";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../../authSlice";
import { EyeInvisible, EyeVisible } from "../../../../assets/icons";

const Header = () => {
   const initialValues = {
      email: "",
      password: ""
   };

   const [checked, setChecked] = useState(false);
   const [icon, setIcon] = useState(EyeInvisible);
   const dispatch = useDispatch();
   const navigate = useNavigate();
   const { isLoading } = useSelector((state) => state.auth);

   const [type, setType] = useState("password");

   const onChange = (e) => {
      setChecked(e.target.checked);
   };

   const LoginSchema = Yup.object().shape({
      email: Yup.string()
         .email("Invalid email")
         .required("Required"),
      password: Yup.string()
         .max(16, "Maximum 16 symbols")
         .required("Required")
   });

   const handleToggle = () => {
      if (type === "password") {
         setIcon(EyeVisible);
         setType("text");
      } else {
         setIcon(EyeInvisible);
         setType("password");
      }
   };
   const headerRef = useRef(null);
   const [isMobile, setIsMobile] = useState(false);

   const submitForm = (values) => {
      dispatch(login(values, successCallback, errCB));
   };

   const successCallback = () => {
      navigate("/admin/workspace");
   };

   const errCB = (data) => {
      if (data.target?.targetUrl) {
         navigate(data.target?.targetUrl, {
            state: { fromApp: true, data: data.target.params }
         });
      }
   };

   useEffect(() => {
      const handleResize = () => {
         setIsMobile(window.innerWidth <= 540);
      };
      handleResize();
      window.addEventListener("resize", handleResize);

      return () => window.removeEventListener("resize", handleResize);
   }, []);

   const heroContainerSudan = (
      <div className="hero-text" style={{ marginBottom: isMobile ? "40px" : "unset" }}>
         <div className="d-flex flex-column" style={{ gap: isMobile ? "24px" : "unset" }}>
            <span
               style={{
                  fontStyle: "italic",
                  fontWeight: 700,
                  color: isMobile ? "#262633" : "#fff"
               }}
               className="spanStyle"
            >
               Empowering Sudanese NGOs for{" "}
               <span
                  style={{
                     fontStyle: "italic",
                     fontWeight: 700,
                     color: isMobile ? "#262633" : "#C0F9F8"
                  }}
                  className="spanStyle"
               >
                  Impactful Humanitarian Work
               </span>
            </span>
         </div>
         <p
            style={{
               fontWeight: 500,
               fontSize: "18px",
               marginTop: isMobile ? "24px" : "unset",
               paddingBottom: 0
            }}
         >
            Sudan KAPorg: Your Pathway to Organizational Excellence and Localization
         </p>
         <p style={{ fontWeight: 500, fontSize: "18px", paddingTop: 0 }}>
            Sudan KAPorg is a comprehensive platform designed to bolster the organizational
            development and capacity-building of Sudanese Non-Governmental Organizations (NGOs) and
            Civil Society Organizations (CSOs).
         </p>
         {/* <button
                className="d-flex justify-content-center align-items-center"
                style={{ borderBottom: "1px solid #fff", width: "fit-content" }}
              >
                Learn more
                <img src={RightArrow} alt="arrow" />
              </button> */}
      </div>
   );

   return (
      <>
         <div ref={headerRef} className="headerLogo-sudan">
            <div className="container">
               <div className="logo d-flex align-items-center">
                  <img src={SudanFlag} alt="forAfrika" />
                  <span
                     style={{
                        fontSize: "18px",
                        color: "#3A3737",
                        fontWeight: 600
                     }}
                  >
                     Sudan
                  </span>
                  <div className="vertical-line"></div>

                  <img src={KaporgBlueIcon} alt="kaporg icon" />
               </div>
            </div>
         </div>
         {isMobile && heroContainerSudan}

         <div className="hero-bg-sudan">
            <div className="img-bg">
               <img src={Banner} alt="img-background" />
               <div className="banner-overlay" />
            </div>
            <div className="container">
               <div className="hero-banner-content">
                  {!isMobile && heroContainerSudan}
                  <div className="hero-form justify-content-center justify-content-lg-end">
                     <>
                        {/* {isLoading ? (
                    <BlockUI isLoading={isLoading} />
                ) : ( */}
                        <div
                           className="pt-md-20 pr-md-10 pb-md-20 pl-md-10 custom-div"
                           style={{
                              margin: "0 !important",
                              display: "block",
                              padding: "60px 40px 60px 40px",
                              height: "fit-content",
                              backgroundColor: "#fff"
                           }}
                        >
                           <div
                              className="d-flex flex-column"
                              style={{ gap: "8px", marginBottom: "40px" }}
                           >
                              <p
                                 style={{
                                    color: "#4158CF",
                                    fontWeight: 600,
                                    fontSize: "26px",
                                    margin: 0
                                 }}
                              >
                                 Welcome back!
                              </p>
                              <p className="m-0">Login into your account</p>
                           </div>
                           <Formik
                              initialValues={initialValues}
                              validationSchema={LoginSchema}
                              onSubmit={(values) => {
                                 submitForm(values);
                              }}
                           >
                              {({ handleSubmit, handleChange, values, errors }) => (
                                 <form
                                    onSubmit={handleSubmit}
                                    className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
                                 >
                                    <div
                                       style={{
                                          display: "flex",
                                          flexDirection: "column",
                                          gap: "30px"
                                       }}
                                    >
                                       <div className="form-group fv-plugins-icon-container">
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Email
                                          </label>
                                          <input
                                             placeholder="yourmail@gmail.com"
                                             className={`form-control`}
                                             type="email"
                                             name="email"
                                             onChange={handleChange}
                                             values={values.email}
                                          />
                                          {errors.email && (
                                             <div className="error-message">{errors.email}</div>
                                          )}
                                       </div>
                                       <div className="form-group fv-plugins-icon-container">
                                          <label
                                             style={{
                                                fontSize: "16px",
                                                color: "#3A3737",
                                                fontWeight: 500,
                                                marginBottom: "10px"
                                             }}
                                          >
                                             Password
                                          </label>
                                          <div className="password-section">
                                             <input
                                                autoComplete="off"
                                                placeholder="Password"
                                                className={`form-control`}
                                                type={type}
                                                name="password"
                                                onChange={handleChange}
                                                values={values.password}
                                             />
                                             <img
                                                className="eyeButton"
                                                onClick={handleToggle}
                                                src={icon}
                                                alt="showPasswordIcon"
                                                style={{ width: "14px" }}
                                             />
                                          </div>

                                          {errors.password && (
                                             <div className="error-message">{errors.password}</div>
                                          )}
                                       </div>
                                    </div>
                                    <div className="d-flex flex-column flex-md-row justify-content-between align-items-md-center mt-2">
                                       <div className="d-flex align-items-center">
                                          <Checkbox
                                             checked={checked}
                                             onChange={onChange}
                                             style={{ lineHeight: "0px" }}
                                          />
                                          <p
                                             style={{
                                                fontSize: "12px",
                                                color: "#777",
                                                margin: "0 0 0 8px"
                                             }}
                                          >
                                             Remember me?
                                          </p>
                                       </div>
                                       <Link
                                          to="/auth/forgot-password"
                                          className="password-text"
                                          id="kt_login_forgot"
                                          style={{ fontSize: "12px" }}
                                       >
                                          <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
                                       </Link>
                                    </div>
                                    <div className="form-group d-flex flex-wrap justify-content-center align-items-center">
                                       <button
                                          id="kt_login_signin_submit"
                                          type="submit"
                                          className={`btn sign-btn my-3 mt-18`}
                                       >
                                          {" "}
                                          {/* Log in */}
                                          {isLoading ? <Spin /> : "Log in"}
                                       </button>
                                    </div>
                                 </form>
                              )}
                           </Formik>
                           <div className="d-flex justify-content-center mt-4">
                              <p
                                 style={{
                                    color: "#555",
                                    fontWeight: 400,
                                    fontSize: "14px",
                                    margin: "0",
                                    padding: 0
                                 }}
                              >
                                 Don't have an account yet?{" "}
                                 <Link
                                    to="/auth/registration"
                                    className="ml-2 sign-up-btn"
                                    id="kt_login_signup"
                                 >
                                    Sign up
                                 </Link>
                              </p>
                           </div>
                        </div>
                        {/* )} */}
                     </>
                  </div>
               </div>
            </div>
            <div className="form-banner"></div>
         </div>
      </>
   );
};

export default Header;
