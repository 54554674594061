import React, { useRef } from "react";
import { Button } from "antd";
import SudanezeOrgs from "../assets/sudaneze-orgs.svg";
import "./styles.scss";
const FundingNetworks = () => {
  
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  return (
    <div className="container">
      <div
        className="d-md-flex align-items-center boxContainer-sudan"
        style={{ gap: "80px" }}
      >
        <div className="d-flex flex-column mb-sm-4" style={{ gap: "16px" }}>
          <span style={{ color: "#003DAA", fontSize: "38px", fontWeight: 600 }}>
            KAPorg: Bridging the Funding Gap.
          </span>
          <div className="d-flex flex-column" style={{ gap: "20px" }}>
            <span
              style={{
                fontSize: "16px",
                color: "#3A3737",
                letterSpacing: "0.32px",
              }}
            >
              KAPorg is committed to empowering Sudanese Organizations by:
              helping Organizations expand funding networks and by equipping
              organizations for sustainable funding management.
            </span>
            <span
              style={{
                fontSize: "16px",
                color: "#3A3737",
                letterSpacing: "0.32px",
              }}
            >
              Partner with KAPorg to secure long-term support and continue
              making a lasting impact.
            </span>
          </div>
          <Button
            onClick={scrollToTop}
            className="btn-sudan"
            type="primary"
            style={{
              width: "fit-content",
              backgroundColor: "#4158CF",
              fontWeight: 700,
              borderRadius: "4px",
            }}
          >
            Get started now
          </Button>
        </div>
        <div className="col-md-6 d-flex justify-content-center align-items-center">
          <img src={SudanezeOrgs} alt="Child Eating" className="img-fluid" />
        </div>
      </div>
    </div>
  );
};

export default FundingNetworks;
