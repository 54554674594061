import React from "react";
import "./styles.scss";
import SudanMap from "../assets/Sudan-Map.svg";

export const PartnerWithKAP = () => {
  return (
    <div className="section">
      <div className="container">
        <div className="row" style={{ alignItems: "center" }}>
          <div className="col-md-6">
            <div className="text-content">
              <span className="partner-with-kaporg">
                Partner with KAPorg to achieve your organization&#39;s goals and
                make a lasting impact.
              </span>
              <p className="sudanese">
                <span className="text-wrapper">Sudanese Organizations </span>
                <span className="span-sudan">
                  face significant hurdles in accessing and implementing
                  effective capacity-building programs. These limitations can
                  hinder their ability to deliver quality services and achieve
                  their goals. <br />
                  <br />
                </span>
                <span className="text-wrapper">Capacity Building Crisis: </span>
                <span className="span-sudan">
                  Hampering Sudanese Organizations. Despite the abundance of
                  NGOs and Civil Society Organizations in Sudan, their ability
                  to effectively execute humanitarian and development work is
                  significantly hindered by a severe lack of organizational
                  capacity-building programs and funding. This deficiency,
                  primarily driven by limited access to financial resources,
                  ongoing violence and brain drain has created a critical
                  obstacle that restricts their impact on the ground. <br />
                  <br />
                  The majority of Sudanese Organizations heavily depend on
                  short-term grants from external donors, making it challenging
                  to plan and execute long-term, sustainable projects. This
                  reliance on limited and fluctuating funding sources creates
                  instability and restricts their ability to address the root
                  causes of crises in the region. <br />
                  <br />A Dependency Gap. As one NGO representative expressed,
                  &#34;Sudanese NGOs are heavily reliant on donor support, which
                  has created a disconnect between their activities and the
                  actual needs of the communities they serve.&#34;
                </span>
              </p>
            </div>
          </div>
          <div className="col-md-6">
            <img className="sudan-map" alt="Sudan map" src={SudanMap} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerWithKAP;
