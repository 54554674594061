import React from "react";
import "./styles.scss";
import SudanezePeople from "../assets/sudaneze-people.svg";

const OrganizationalDevelopment = () => {
  return (
    <div
      className="mainContainer-sudan"
      style={{
        backgroundColor: "#DFE6FF",
      }}
    >
      <div className="container">
        <div className="mainContainer-wrapper">
          <div>
            <img
              src={SudanezePeople}
              alt="People"
              className="img-fluid"
              style={{
                height: "100%",
                maxWidth: "100%",
                objectFit: "cover",
                borderRadius: "16px",
              }}
            />
          </div>
          <div className="text-container-sudan text-diff" style={{ gap: "32px" }}>
            <div className="d-flex flex-column" style={{ gap: "24px" }}>
              <span
                style={{
                  fontSize: "32px",
                  fontWeight: 600,
                  color: "#003DAA",
                  margin: 0,
                }}
              >
                Elevate your Sudanese NGO or CSO with KAPorg, the ultimate
                platform for organizational development and capacity-building.{" "}
              </span>
              <strong
                style={{
                  fontSize: "18px",
                  color: "#3A3737",
                }}
              >
                Our comprehensive tool empowers you to:
              </strong>
              <div>
                <ul
                  className="d-flex flex-column"
                  style={{ padding: "0 0 0 25px" }}
                >
                  <li style={{ color: "#3A3737" }}>
                    <strong>Assess and Showcase Your Strengths</strong>:
                    Evaluate your organization's readiness across key areas like
                    project management, governance, fundraising, finance, and
                    external relations.
                  </li>
                  <li style={{ color: "#3A3737" }}>
                    <strong>Demonstrate Effectiveness</strong>: Prove your
                    capabilities and advocate for localization in humanitarian
                    and development work.
                  </li>
                  <li style={{ color: "#3A3737" }}>
                    <strong>Drive Positive Change</strong>: Strengthen your
                    organization's impact and contribute to a more sustainable
                    future.
                  </li>
                </ul>
                <span>
                  Join the KAPorg community today and unlock your organization's
                  full potential.
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrganizationalDevelopment;
